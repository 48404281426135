body {
  background-color: #f7f8ff;
  line-height: 28px;
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

body {
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;

  margin: 0;
  font-family: var(--font-family);

  code:not([class]) {
    color: #fe79c6;
    border: 1px solid #434548;
    background-color: #272a36;
    border-radius: 3px;
    padding: 1px 4px;
  }

  pre[class*="language-"] {
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 801px) {
      margin: 0 -12px;
    }
  }
}

pre {
  table {
    margin: 0 auto;
  }

  table {
    color: #333;
    background: white;
    border: 1px solid grey;
    font-size: 12pt;
    border-collapse: collapse;
  }

  table thead th,
  table tfoot th {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
  }

  table caption {
    padding: 0.5em;
  }

  table th,
  table td {
    padding: 0.5em;
    border: 1px solid lightgrey;
  }

  img {
    max-width: 100%;
  }
}

p {
  font-weight: 600;
  font-size: 18px;
}

ng-me-nghost,
ng-me-directive-context,
ng-me-angular-components-in-markdown {
  display: block;
  max-width: 800px;
  margin: 2rem auto 0;

  @media screen and (max-width: 801px) {
    max-width: calc(100% - 24px);
  }
}

blog-navbar {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1001;

  header {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px;
    white-space: nowrap;
    background: #232323;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: normal;
    margin: 0;
    height: 64px;

    .links {
      display: flex;
      align-items: center;
      gap: 1rem;

      .posts,
      .about,
      .resume,
      .shield-maker {
        @media screen and (max-width: 700px) {
          display: none;
        }
      }

      a {
        text-decoration: none;
        color: #ffffff;

        &:hover {
          opacity: 0.8;
        }

        img {
          aspect-ratio: 1;
          width: 25px;
          filter: contrast(0) brightness(2);
        }
      }
    }

    .icon-links {
      a {
        height: 25px;
        width: 25px;
        &.BlueSky-icon {
          img {
            aspect-ratio: 1.2 / 1;
          }
        }
      }
    }
  }
}

html,
body {
  height: 100%;
}

#overlay {
  position: fixed;
  z-index: -1;
  inset: 0;
  width: 100%;
  height: 100%;
  background: url(assets/particles.gif);
  background-size: contain;
  filter: blur(20px);
  opacity: 0.25;
}
body {
  --electric-violet: oklch(53.18% 0.28 296.97 / 0.6);
  --french-violet: oklch(47.66% 0.246 305.88 / 0.65);
  --vivid-pink: oklch(69.02% 0.277 332.77 / 0.7);
  --hot-pink: oklch(59.91% 0.239 8.14 / 0.75);
  --hot-red: oklch(61.42% 0.238 15.34 / 0.8);
  --orange-red: oklch(63.32% 0.24 31.68 / 0.5);

  --bright-blue: oklch(51.01% 0.274 263.83 / 0.55);
  --indigo-blue: oklch(51.64% 0.229 281.65 / 0.55);
  --super-green: oklch(79.12% 0.257 155.13 / 0.55);

  margin: 0;
  padding-top: 64px;
  height: calc(100% - 64px);
  background: linear-gradient(
    217deg,
    var(--bright-blue) 0%,
    var(--indigo-blue) 30%,
    var(--super-green) 45%,
    var(--bright-blue) 60%,
    var(--indigo-blue) 70%,
    var(--super-green) 100%
  );
  background-attachment: fixed;
}

/*
* Dracula Theme for Prism.JS
*
* @author Gustavo Costa
* e-mail: gusbemacbe@gmail.com
*
* @contributor Jon Leopard
* e-mail: jonlprd@gmail.com
*
* @license MIT 2016-2020
*/

:root {
  --background: #282a36;
  --comment: #6272a4;
  --foreground: #f8f8f2;
  --selection: #44475a;

  --cyan: #8be9fd;
  --green: #50fa7b;
  --orange: #ffb86c;
  --pink: #ff79c6;
  --purple: #bd93f9;
  --red: #ff5555;
  --yellow: #f1fa8c;

  /* Transparency */

  /** 30% of transparency **/
  --background-30: #282a3633;
  --comment-30: #6272a433;
  --foreground-30: #f8f8f233;
  --selection-30: #44475a33;

  --cyan-30: #8be9fd33;
  --green-30: #50fa7b33;
  --orange-30: #ffb86c33;
  --pink-30: #ff79c633;
  --purple-30: #bd93f933;
  --red-30: #ff555533;
  --yellow-30: #f1fa8c33;

  /** 40% of transparency **/
  --background-40: #282a3666;
  --comment-40: #6272a466;
  --foreground-40: #f8f8f266;
  --selection-40: #44475a66;

  --cyan-40: #8be9fd66;
  --green-40: #50fa7b66;
  --orange-40: #ffb86c66;
  --pink-40: #ff79c666;
  --purple-40: #bd93f966;
  --red-40: #ff555566;
  --yellow-40: #f1fa8c66;
}

pre::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

pre::-webkit-scrollbar-thumb {
  background-color: #00000075;
}

pre::-webkit-scrollbar-track {
  background-color: #3f50b5;
  border-radius: 0px;
}

/* Selection */

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background-color: var(--selection);
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background-color: var(--selection);
}

/* Line numbers */

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
  white-space: inherit;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em; /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #999;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}

/* Toolbar for copying */

div.code-toolbar {
  position: relative;
}

div.code-toolbar > .toolbar {
  position: absolute;
  top: 0.3em;
  right: 0.2em;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

div.code-toolbar:hover > .toolbar {
  opacity: 1;
}

div.code-toolbar > .toolbar .toolbar-item {
  display: inline-block;
  padding-right: 20px;
}

div.code-toolbar > .toolbar a {
  cursor: pointer;
}

div.code-toolbar > .toolbar button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar button,
div.code-toolbar > .toolbar span {
  color: var(--foreground);
  font-size: 0.8em;
  padding: 0.5em;
  background: var(--comment);
  border-radius: 0.5em;
}

div.code-toolbar > .toolbar a:hover,
div.code-toolbar > .toolbar a:focus,
div.code-toolbar > .toolbar button:hover,
div.code-toolbar > .toolbar button:focus,
div.code-toolbar > .toolbar span:hover,
div.code-toolbar > .toolbar span:focus {
  color: inherit;
  text-decoration: none;
  background-color: var(--green);
}

/* Remove text shadow for printing */

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

code[class*="language-"],
pre[class*="language-"] {
  color: var(--foreground);
  background: var(--background);
  text-shadow: none;
  font-family: PT Mono, Consolas, Monaco, "Andale Mono", "Ubuntu Mono",
    monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 28px;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */

pre[class*="language-"] {
  background: var(--background);
  border-radius: 0.5em;
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  height: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: var(--background);
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 4px 7px;
  border-radius: 0.3em;
  white-space: normal;
}

/* Code box limit */

.limit-300 {
  height: 300px !important;
}

.limit-300 {
  height: 400px !important;
}

.limit-500 {
  height: 500px !important;
}

.limit-600 {
  height: 600px !important;
}

.limit-700 {
  height: 700px !important;
}

.limit-800 {
  height: 800px !important;
}

.language-css {
  color: var(--purple);
}

.token {
  color: var(--pink);
}

.language-css .token {
  color: var(--pink);
}

.token.script {
  color: var(--foreground);
}

.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.atrule,
.token.attr-name,
.token.attr-value {
  color: var(--green);
}

.language-css .token.atrule {
  color: var(--purple);
}

.language-html .token.attr-value,
.language-markup .token.attr-value {
  color: var(--yellow);
}

.token.boolean {
  color: var(--purple);
}

.token.builtin,
.token.class-name {
  color: var(--cyan);
}

.token.comment {
  color: var(--comment);
}

.token.constant {
  color: var(--purple);
}

.language-javascript .token.constant {
  color: var(--orange);
  font-style: italic;
}

.token.entity {
  color: var(--pink);
}

.language-css .token.entity {
  color: var(--green);
}

.language-html .token.entity.named-entity {
  color: var(--purple);
}

.language-html .token.entity:not(.named-entity) {
  color: var(--pink);
}

.language-markup .token.entity.named-entity {
  color: var(--purple);
}

.language-markup .token.entity:not(.named-entity) {
  color: var(--pink);
}

.token.function {
  color: var(--green);
}

.language-css .token.function {
  color: var(--cyan);
}

.token.important,
.token.keyword {
  color: var(--pink);
}

.token.prolog {
  color: var(--foreground);
}

.token.property {
  color: var(--orange);
}

.language-css .token.property {
  color: var(--cyan);
}

.token.punctuation {
  color: var(--pink);
}

.language-css .token.punctuation {
  color: var(--orange);
}

.language-html .token.punctuation,
.language-markup .token.punctuation {
  color: var(--foreground);
}

.token.selector {
  color: var(--pink);
}

.language-css .token.selector {
  color: var(--green);
}

.token.regex {
  color: var(--red);
}

.language-css .token.rule:not(.atrule) {
  color: var(--foreground);
}

.token.string {
  color: var(--yellow);
}

.token.tag {
  color: var(--pink);
}

.token.url {
  color: var(--cyan);
}

.language-css .token.url {
  color: var(--orange);
}

.token.variable {
  color: var(--comment);
}

.token.number {
  color: rgba(189, 147, 249, 1);
}

.token.operator {
  color: rgba(139, 233, 253, 1);
}

.token.char {
  color: rgba(255, 135, 157, 1);
}

.token.symbol {
  color: rgba(255, 184, 108, 1);
}

.token.deleted {
  color: #e2777a;
}

.token.namespace {
  color: #e2777a;
}

/* Line Highlighter */

.highlight-line {
  color: inherit;
  display: inline-block;
  text-decoration: none;

  border-radius: 4px;
  padding: 2px 10px;
}

.highlight-line:empty:before {
  content: " ";
}

.highlight-line:not(:last-child) {
  min-width: 100%;
}

.highlight-line .highlight-line:not(:last-child) {
  min-width: 0;
}

.highlight-line-isdir {
  color: var(--foreground);
  background-color: var(--selection-30);
}

.highlight-line-active {
  background-color: var(--comment-30);
}

.highlight-line-add {
  background-color: var(--green-30);
}

.highlight-line-remove {
  background-color: var(--red-30);
}
